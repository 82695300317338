import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import UpperNavbar from './UpperNavbar';
import blogImg1 from '../Components/Images/50c06ees-1920.jpg'

const BlogDetail = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await fetch(`https://api.minicaberz.com/api/admin/blogs/${id}`);
        const data = await response.json();
        setBlog(data);
      } catch (error) {
        console.error('Error fetching blog:', error);
      }
    };

    fetchBlog();
  }, [id]);

  if (!blog) {
    return <p>Loading...</p>;
  }

  return (
    <div className="relative p-[0.1px]">
      <UpperNavbar />
      <Navbar />
      <div className="w-[100%] blogImg1">
        <div className="w-[100%] h-[100%] bg-[#00000067] flex items-center justify-center">
          <p className='w-[95%] sm:w-[90%] 2xl:w-[1450px] text-center text-[50px] text-[white] font-bold'>{blog.title}</p>
        </div>
        {/* <img className='w-[100%]' src={blogImg1} alt="" /> */}
      </div>
    <div className="container mx-auto p-4">
      <h2 className="text-3xl font-bold mb-4">{blog.title}</h2>
      <p className="text-gray-600 mb-2">By {blog.author}</p>
      <p className="text-gray-500 mb-4">{new Date(blog.date).toLocaleDateString()}</p>
      
      {/* Render rich text content */}
      <div dangerouslySetInnerHTML={{ __html: blog.content }} className="prose" />
    </div>
    <Footer />
    </div>
  );
};

export default BlogDetail;
