import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaEdit, FaTrash } from "react-icons/fa";
import AddFareForm from "./AddFareForm";
import { toast } from "react-toastify";


function AdminCoveragePrice() {
  const [fares, setFares] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editFareData, setEditFareData] = useState(null); // For editing fares
  const [peakHours,setpeakHours] = useState([])
  const getUKTime = () => {
    const options = {
      timeZone: 'Europe/London', // UK time zone
      hour: '2-digit',
      minute: '2-digit',
      // second: '2-digit',
      hour12: false
    };
  
    return new Date().toLocaleTimeString('en-GB', options);
  };
  const [peakData, setpeakData] = useState({
    fromDate: "",
    toDate: "",
    fromTime: getUKTime(),
    toTime: getUKTime(),
    incrementType: "",
    incrementRate: ""
  });
  const fetchData = async () => {
    try {
      const token = localStorage.getItem("adminToken");

      const fareResponse = await axios.get(
        "https://api.minicaberz.com/api/admin/fares",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const peakResponse= await axios.get("https://api.minicaberz.com/api/peak-hours/peak", peakData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setFares(fareResponse.data);
      setpeakHours(peakResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error.response?.data);
    }
  };
  const handleInputChange = (e) => {
    setpeakData({ ...peakData, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    fetchData();
  }, []);

  // Open modal for adding new fare
  const handleAdd = () => {
    setEditFareData(null); // Ensure no data is being edited
    setOpenModal(true);
  };

  // Open modal for editing fare
  const handleEdit = (fare) => {
    setEditFareData(fare); // Pass fare data to the modal
    setOpenModal(true);
  };

  // Delete a fare
  const handleDelete = async (fareId) => {
    try {
      await axios.delete(`https://api.minicaberz.com/api/fares/${fareId}`);
      toast.success("Fare deleted successfully");
      fetchData(); // Refresh the list
    } catch (error) {
      console.error("Failed to delete fare:", error);
      alert("Failed to delete fare");
    }
  };
  const [activeTab, setActiveTab] = useState("distance");
  const [selectedOption, setSelectedOption] = useState("dateTime");

  const handleAddPeakHour = async () => {
    try {
      
  
      const token = localStorage.getItem("adminToken");
      await axios.post("https://api.minicaberz.com/api/peak-hours", peakData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      toast.success("Peak hour added successfully!");
      fetchData(); // Refresh data
      setpeakData({
        fromDate: "",
        toDate: "",
        fromTime: "",
        toTime: "",
        incrementType: "",
        incrementRate: ""
      })
    } catch (error) {
      console.error("Failed to add peak hour:", error.response?.data);
      toast.error("Failed to add peak hour");
    }
  };

  const [editingPeakHour, setEditingPeakHour] = useState(null); // For editing
  const formatToInputDate = (isoString) => {
    return isoString ? isoString.split("T")[0] : "";
  };
  
// Edit Peak Hour
const handleEditpeak = (job) => {
  setEditingPeakHour(job);
  console.log(job.fromDate);
  
  setpeakData({
    fromDate: formatToInputDate(job.fromDate),
    toDate: formatToInputDate(job.toDate),
    fromTime: job.fromTime,
    toTime: job.toTime,
    incrementType: job.incrementType,
    incrementRate: job.incrementRate,
  });
};

// Update Peak Hour
const handleUpdatePeakHour = async () => {
  try {
    const token = localStorage.getItem("adminToken");
    await axios.put(
      `https://api.minicaberz.com/api/peak-hours/${editingPeakHour._id}`,
      peakData,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    toast.success("Peak hour updated successfully!");
    fetchData(); // Refresh data
    setEditingPeakHour(null); // Reset editing state
    setpeakData({
      fromDate: "",
      toDate: "",
      fromTime: "",
      toTime: "",
      incrementType: "",
      incrementRate: "",
    });
  } catch (error) {
    console.error("Failed to update peak hour:", error.response?.data);
    toast.error("Failed to update peak hour");
  }
};

// Delete Peak Hour
const handleDeletepeak = async (id) => {
  try {
    const token = localStorage.getItem("adminToken");
    await axios.delete(`https://api.minicaberz.com/api/peak-hours/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    toast.success("Peak hour deleted successfully!");
    fetchData(); // Refresh data
  } catch (error) {
    console.error("Failed to delete peak hour:", error.response?.data);
    toast.error("Failed to delete peak hour");
  }
};

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, options);
};

  return (
    <div className="flex flex-col w-[100%] pt-[75px] bg-[#E9E9EB] h-[100vh]">
      <div className="w-[100%]">
        <div className="w-[100%] flex justify-between items-center pt-3 pl-10 pr-6">
          <p className="text-[24px] font-semibold">DISTANCE PRICES</p>
        </div>
        <div className="w-[100%] flex justify-between pt-5 pb-20 pl-6 pr-6"></div>
        <div className="w-[95%] h-[62vh] custom-scrollbar rounded-xl pt-4 mt-[-65px] ml-[2.5%] bg-[white] border">
          <div className="w-[100%] flex justify-between items-center">
            <div className="flex ml-2">
              <button
                onClick={() => setActiveTab("distance")}
                className={`${
                  activeTab === "distance"
                    ? "bg-white text-[#2649AD]"
                    : "bg-[#2649AD] text-white rounded-t-lg"
                } text-[18px] font-semibold pt-1 pb-1 pl-5 pr-5 mr-3`}
              >
                Distance Prices
              </button>
              <button
                onClick={() => setActiveTab("peakhours")}
                className={`${
                  activeTab === "peakhours"
                    ? "bg-white text-[#2649AD]"
                    : "bg-[#2649AD] text-white rounded-t-lg"
                } text-[18px] font-semibold pt-1 pb-1 pl-5 pr-5 mr-3`}
              >
                Peak Hours
              </button>
            </div>

            <button
              onClick={handleAdd}
              className="text-[13px] text-white rounded-lg mb-4 mr-2 bg-[#95A3EE] p-2"
            >
              Add Fare
            </button>
          </div>
          {activeTab === "distance" && (
            <table className="w-[100%] bg-white border border-gray-200">
              <thead className="w-[100%]">
                <tr className="w-[100%] bg-[#2649AD] text-white text-[14px] font-semibold text-left">
                  <th className="py-1 px-4 border">MILAGE RANGE</th>
                  <th className="py-1 px-4 border-2">CAR TYPE</th>
                  <th className="py-1 px-4 border-2">1st MILE</th>
                  <th className="py-1 px-4 border-2">PER MILE</th>
                  <th className="py-1 px-4 border-2">ACTION</th>
                </tr>
              </thead>
              <tbody>
                {fares
                  ? fares.map((job, index) => (
                      <tr
                        key={index}
                        className={`text-left text-[14px] ${
                          index % 2 === 0 ? "bg-gray-100" : "bg-white"
                        }`}
                      >
                        <td className="py-1 px-4 border-2">
                          {job.milesFrom} - {job.milesTo}
                        </td>
                        <td className="py-1 px-4 border-2">{job.carType}</td>
                        <td className="py-1 px-4 border-2">
                          £{job.firstMilePrice}
                        </td>
                        <td className="py-1 px-4 border-2">
                          £{job.perMilePrice}
                        </td>
                        <td className="py-1 px-4 border-2">
                          <button
                            onClick={() => handleEdit(job)}
                            className="mr-4 text-blue-500"
                          >
                            <FaEdit />
                          </button>
                          <button
                            onClick={() => handleDelete(job._id)}
                            className="text-red-500"
                          >
                            <FaTrash />
                          </button>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          )}
          {activeTab === "peakhours" && (
            
            <div className="w-[100%] pt-6 pl-5 pr-5">
              {/* Radio Buttons */}
              <div className="flex items-center flex-wrap">
                <div className="flex items-center">
                  <input
                    type="radio"
                    className="w-[13px] h-[13px]"
                    name="filter"
                    id="dateTime"
                    value="dateTime"
                    checked={selectedOption === "dateTime"}
                    onChange={(e) => setSelectedOption(e.target.value)}
                  />
                  <p className="text-[16px] font-semibold ml-2">
                    Date & Time Wise
                  </p>
                </div>
                <div className="flex items-center ml-4">
                  <input
                    type="radio"
                    className="w-[13px] h-[13px]"
                    name="filter"
                    id="date"
                    value="date"
                    checked={selectedOption === "date"}
                    onChange={(e) => setSelectedOption(e.target.value)}
                  />
                  <p className="text-[16px] font-semibold ml-2">Date Wise</p>
                </div>
                <div className="flex items-center ml-4">
                  <input
                    type="radio"
                    className="w-[13px] h-[13px]"
                    name="filter"
                    id="time"
                    value="time"
                    checked={selectedOption === "time"}
                    onChange={(e) => setSelectedOption(e.target.value)}
                  />
                  <p className="text-[16px] font-semibold ml-2">Time Wise</p>
                </div>
                <div className="flex items-center ml-4">
                  <input
                    type="radio"
                    className="w-[13px] h-[13px]"
                    name="filter"
                    id="day"
                    value="day"
                    checked={selectedOption === "day"}
                    onChange={(e) => setSelectedOption(e.target.value)}
                  />
                  <p className="text-[16px] font-semibold ml-2">Day Wise</p>
                </div>
              </div>

              {/* Dynamic Fields */}
              <div className="flex items-center flex-wrap mt-4">
                {/* Show From and To Dates */}
                {selectedOption !== "time" && (
                  <>
                    <p className="text-[16px] font-semibold mr-4">From</p>
                    <input
                      type="date"
                      className="pt-1 pb-1 pl-3 pr-3"
                      name="fromDate"
                      id="fromDate"
                      value={peakData.fromDate}
                      onChange={handleInputChange}
                    />
                    {selectedOption === "dateTime" && (
                      <input
                        type="time"
                        className="pt-1 pb-1 pl-3 pr-3 ml-4"
                        name="fromTime"
                        id="fromTime"
                        value={peakData.fromTime}
                      onChange={handleInputChange}
                      />
                    )}
                    <p className="text-[16px] font-semibold mr-4 ml-4">To</p>
                    <input
                      type="date"
                      className="pt-1 pb-1 pl-3 pr-3"
                      name="toDate"
                      id="toDate"
                      value={peakData.toDate}
                      onChange={handleInputChange}
                    />
                    {selectedOption === "dateTime" && (
                      <input
                        type="time"
                        className="pt-1 pb-1 pl-3 pr-3 ml-4"
                        name="toTime"
                        id="toTime"
                        value={peakData.toTime}
                      onChange={handleInputChange}
                      />
                    )}
                  </>
                )}

                {/* Show Only Time Fields */}
                {selectedOption === "time" && (
                  <>
                    <p className="text-[16px] font-semibold mr-4">From</p>
                    <input
                      type="time"
                      className="pt-1 pb-1 pl-3 pr-3"
                      name="fromTime"
                      id="fromTime"
                      value={peakData.fromTime}
                      onChange={handleInputChange}
                    />
                    <p className="text-[16px] font-semibold mr-4 ml-4">To</p>
                    <input
                      type="time"
                      className="pt-1 pb-1 pl-3 pr-3"
                      name="toTime"
                      id="toTime"
                      value={peakData.toTime}
                      onChange={handleInputChange}
                    />
                  </>
                )}

                {/* Common Fields */}
                <select
                  name="incrementType"
                  id="incrementType"
                  className="pt-1 pb-1 pl-3 pr-3 ml-4"
                  value={peakData.incrementType}
                      onChange={handleInputChange}
                >
                  <option value="">Increment Type</option>
                  <option value="Percent">Percent</option>
                  <option value="Amount">Amount</option>
                </select>
                <input
                  type="text"
                  placeholder="Increment Rate"
                  className="pt-1 pb-1 pl-3 pr-3 ml-4"
                  name="incrementRate"
                  id="incrementRate"
                  value={peakData.incrementRate}
                      onChange={handleInputChange}
                />
                <button onClick={editingPeakHour ? handleUpdatePeakHour : handleAddPeakHour} className="bg-[#2649AD] rounded-md pt-1 pb-1 pl-5 pr-5 ml-4 text-white">
                {editingPeakHour ? "Update" : "Add"}
                </button>
                <button className="bg-[#2649AD] rounded-md pt-1 pb-1 pl-5 pr-5 ml-4 text-white">
                  Clear
                </button>
              </div>

              <table className="w-[100%] bg-white border border-gray-200 mt-10">
              <thead className="w-[100%]">
                <tr className="w-[100%] bg-[#2649AD] text-white text-[14px] font-semibold text-left">
                  <th className="py-1 px-4 border">FROM</th>
                  <th className="py-1 px-4 border-2">TILL</th>
                  <th className="py-1 px-4 border-2">RATE</th>
                  <th className="py-1 px-4 border-2">TYPE</th>
                  <th className="py-1 px-4 border-2">ACTION</th>
                </tr>
              </thead>
              <tbody>
              {peakHours
                  ? peakHours.map((job, index) => (
                      <tr
                        key={index}
                        className={`text-left text-[14px] ${
                          index % 2 === 0 ? "bg-gray-100" : "bg-white"
                        }`}
                      >
                        <td className="py-1 px-4 border-2">
                          {formatDate(job.fromDate)} - {job.fromTime}
                        </td>
                        <td className="py-1 px-4 border-2">{formatDate(job.toDate)} - {job.toTime}</td>
                        <td className="py-1 px-4 border-2">
                          {job.incrementType}
                        </td>
                        <td className="py-1 px-4 border-2">
                          {job.incrementRate}
                        </td>
                        <td className="py-1 px-4 border-2">
                          <button
                            onClick={() => handleEditpeak(job)}
                            className="mr-4 text-blue-500"
                          >
                            <FaEdit />
                          </button>
                          <button
                            onClick={() => handleDeletepeak(job._id)}
                            className="text-red-500"
                          >
                            <FaTrash />
                          </button>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
            </div>
          )}
        </div>
      </div>
      <AddFareForm
        openModal={openModal}
        setOpenModal={setOpenModal}
        fetchData={fetchData}
        editFareData={editFareData}
      />
    </div>
  );
}

export default AdminCoveragePrice;
