import { FaPlus } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import React, { useState, useEffect, useRef } from "react";
import Slider from "./Slider";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  const [openViaLocation, setOpenViaLocation] = useState(false);
  const getUKTime = () => {
    const options = {
      timeZone: 'Europe/London', // UK time zone
      hour: '2-digit',
      minute: '2-digit',
      // second: '2-digit',
      hour12: false
    };
  
    return new Date().toLocaleTimeString('en-GB', options);
  };
  
  const [formData, setFormData] = useState({
    pickupLocation: "",
    viaLocation: "",
    dropLocation: "",
    route: "one_way",
    date: new Date().toISOString().split("T")[0],
    time: getUKTime(),
    carType: "saloon",
    returnDate: "",
    returnTime: ""
  });
  const [distance, setDistance] = useState(null);
  const [fare, setFare] = useState(null);

  const pickupInputRef = useRef(null);
  const viaInputRef = useRef(null);
  const dropInputRef = useRef(null);
  const [showCheckoutForm, setShowCheckoutForm] = useState(false);



  useEffect(() => {
    setInterval(()=>{

      setFormData((prevData) => ({
        ...prevData,
        time: getUKTime(),
      }));
    },60000)

    const initAutocomplete = (inputRef, fieldName) => {
      const input = inputRef.current;
      if (!input) return;

      const autocomplete = new window.google.maps.places.Autocomplete(input);
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        setFormData((prevState) => ({
          ...prevState,
          [fieldName]: place.formatted_address,
        }));
      });
    };

    if (window.google) {
      initAutocomplete(pickupInputRef, "pickupLocation");
      initAutocomplete(viaInputRef, "viaLocation");
      initAutocomplete(dropInputRef, "dropLocation");
    } else {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAUqM4ouaJI-TpUKkHvGQATb9MN6GLw1o0&libraries=places`;
      script.async = true;
      script.onload = () => {
        initAutocomplete(pickupInputRef, "pickupLocation");
        initAutocomplete(viaInputRef, "viaLocation");
        initAutocomplete(dropInputRef, "dropLocation");
      };
      document.body.appendChild(script);
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  console.log(formData,'hello')
  const calculateDistance = async () => {
    try {
      const destinations = formData.viaLocation
        ? `${formData.viaLocation}|${formData.dropLocation}`
        : formData.dropLocation;

      const response = await axios.get(
        `https://api.minicaberz.com/api/distance?origins=${formData.pickupLocation}&destinations=${destinations}`
      );

      const totalDistanceInMeters = response.data.totalDistance;

      if (typeof totalDistanceInMeters === "number") {
        const totalDistanceInMiles = (totalDistanceInMeters / 1609.34).toFixed(
          2
        );
        const roundedDistanceInMiles = Math.round(totalDistanceInMiles);
        return roundedDistanceInMiles;
      } else {
        console.error("Invalid distance data:", response.data);
      }
    } catch (error) {
      console.error("Error calculating distance:", error);
    }
  };

  const calculateFare = async (distance, carType, date ,time) => {
    try {
      const distanceInMiles = parseFloat(distance);
      console.log(distanceInMiles);
      
      if (isNaN(distanceInMiles)) {
        throw new Error("Invalid distance value");
      }

      const response = await axios.get(
        `https://api.minicaberz.com/api/fares/calculate`,{
          params: {
            carType: carType,
            miles: distanceInMiles,
            date: date,
            time: time // Include the time parameter for peak hour consideration
          }
        }
      );

      const fare = response.data;
      console.log(fare,'backend fareeeee');
      
      // let totalFare = fare.firstMilePrice;
      // if (distanceInMiles > 1) {
      //   totalFare += (distanceInMiles - 1) * fare.perMilePrice;
      // }
      return fare;
    } catch (error) {
      console.error("Failed to calculate fare:", error);
    }
  };

  const [bookButton,setbookButton]= useState("Get Quote")

  const handleDone = async (e) => {
    e.preventDefault();
    const totalDistance = await calculateDistance();
    setDistance(totalDistance);
    let totalFare= null
    if (totalDistance) {
      totalFare = await calculateFare(totalDistance, formData.carType,formData.date,formData.time);
      setFare(totalFare.totalFare);
      console.log(totalFare);
      
    }
    // setOpenButton(true);
    setShowCheckoutForm(true);
    // setOpenButton(true);
    if(bookButton === "Get Quote"){
      setbookButton("Book")
    }
    else{

      if (totalDistance && totalFare !== null) {
        navigate('/checkout', { state: { formData, distance: totalDistance, fare: totalFare } });
      } else {
        console.error("Navigation failed: Distance or Fare is invalid.");
      }
    }
  };

  

  return (
    <div className="w-[100%] h-[80vh] mt-[80px] relative">
      <Slider />
      <div className="absolute flex items-center justify-center top-0 left-0 w-[100%] h-[100%] z-50">
        <div className="w-[95%] sm:w-[90%] 2xl:w-[1450px] flex items-center justify-center sm:justify-end">
          <div className="w-[80%] sm:w-[35%] pt-4 pb-4 bg-[#253754c1] rounded-xl flex flex-col items-center justify-center">
            <input
              type="text"
              name="pickupLocation"
              value={formData.pickupLocation}
              onChange={handleInputChange}
              placeholder="Pickup Location"
              className="w-[90%] rounded-full"
              ref={pickupInputRef}
            />
            <div className="flex items-center w-[90%] mt-2 relative">
              <p className="text-[white] text-[13.5px] pl-2 font-semibold">
                VIA LOCATION
              </p>
              <div className="rounded-full p-1 ml-2 flex items-center justify-center text-white text-[12px] bg-slate-400">
                <FaPlus onClick={() => setOpenViaLocation(true)} />
              </div>
              <FaXmark
                onClick={() => setOpenViaLocation(false)}
                className={`${
                  openViaLocation ? "flex" : "hidden"
                } text-[20px] text-white absolute top-0 right-0`}
              />
            </div>
            <input
              type="text"
              name="viaLocation"
              value={formData.viaLocation}
              onChange={handleInputChange}
              placeholder="Via Location"
              className={`${
                openViaLocation ? "flex" : "hidden"
              } w-[90%] rounded-full mt-1`}
              ref={viaInputRef}
            />
            <input
              type="text"
              name="dropLocation"
              value={formData.dropLocation}
              onChange={handleInputChange}
              placeholder="Drop Location"
              className="w-[90%] mt-2 rounded-full"
              ref={dropInputRef}
            />
            <div className="w-[90%] mt-4 border-t-2 border-blue-800">
              <p className="text-white text-[13.5px] mt-2 pl-2 font-semibold">
                ROUTE
              </p>
              <div className="w-[100%] flex justify-between">
                <div className="text-white pl-2">
                  <input
                    type="radio"
                    name="route"
                    value="one_way"
                    checked={formData.route === "one_way"}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  One way
                </div>
                <div className="text-white pr-2">
                  <input
                    type="radio"
                    name="route"
                    value="return"
                    checked={formData.route === "return"}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  Return
                </div>
              </div>
            </div>
            <div className="flex justify-between w-[90%]">
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
                className="w-[48%] rounded-full mt-2"
              />
              <input
                type="time"
                name="time"
                value={formData.time}
                onChange={handleInputChange}
                className="w-[48%] rounded-full mt-2"
              />
            </div>
            {formData.route === "return" && (
              <div className="flex justify-between w-[90%]">
                <input
                  type="date"
                  name="returnDate"
                  value={formData.returnDate}
                  onChange={handleInputChange}
                  className="w-[48%] rounded-full mt-2"
                />
                <input
                  type="time"
                  name="returnTime"
                  value={formData.returnTime}
                  onChange={handleInputChange}
                  className="w-[48%] rounded-full mt-2"
                />
              </div>
            )}
            <div className="w-[90%]">
              <select
                className="w-[100%] rounded-full mt-4 text-[13px]"
                value={formData.carType}
                name="carType"
                onChange={handleInputChange}
              >
                <option value="saloon">Saloon</option>
                <option value="Estate">Estate</option>
                <option value="MPV">MPV</option>
                <option value="Executive">Executive</option>
                <option value="e-class">E Class Mercedes</option>
                <option value="v-class">V Class Mercedes</option>
                <option value="s-class">S Class Mercedes</option>
              </select>
            </div>
            <p className="text-white pl-2 mt-3 w-[90%] text-[18px]">{fare ? `Price : £${parseFloat(fare).toFixed(2)}` : null}</p>
            <div
              onClick={handleDone}
              className="flex items-center justify-center w-[90%] mt-4 p-3 bg-[#4181FF] rounded-full text-white cursor-pointer"
            >
              {bookButton}
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}


